export const desktopX = 1900;
export const desktopL = 1800;
export const desktopS = 1700;
export const desktopM = 1600;
export const desktop = 1390;
export const laptop = 1200;
export const laptopS = 1024;
export const tabletS = 920;
export const tablet = 768;
export const mobileXL = 680;
export const mobileL = 570;
export const mobileS = 500;
export const mobileLM = 480;
export const mobileM = 425;
export const mobileMS = 410;
export const mobileXS = 370;
export const xs = 0;

export const breakpoints = {
  desktopX,
  desktopL,
  desktopS,
  desktopM,
  desktop,
  laptop,
  tabletS,
  tablet,
  mobileXL,
  mobileL,
  mobileM,
  mobileLM,
  mobileS,
  mobileXS,
  mobileMS,
  xs,
};
